<template>
    <div class="action-contact-request">
        <div class="title" v-html="title" />
        <div class="body p-b-12">
            <div v-html="body" />
        </div>
        <div v-if="showProfileInfo" class="profile-info-container">
            <div class="profile-info-box">
                <div class="name">{{ content.nickname || '' }}, {{ $options.filters.asAge(content.birthday) }}</div>
                <div class="height-region">
                    <span class="cm">{{ content.height || '-' }}cm</span>
                    <span class="bar">ㅣ</span>
                    <span class="region">{{ (content.region || '').split('(')[0] }}</span>
                </div>
            </div>
        </div>

        <template v-if="showButton">
            <div class="btn-container" :class="{ kakaoMode: $kakaoMode() }">
                <button
                    @click="buyContact"
                    class="btn btn-primary btn-block"
                    v-if="items.tickets_count > 0 && !content.accepted"
                >
                    <span v-html="$translate('GET_CONTACT')"></span>
                    <div class="container">{{ items.tickets_count }}장 보유</div>
                </button>
                <button
                    @click="buyTicket"
                    class="btn btn-primary btn-block"
                    v-if="items.tickets_count === 0 && !content.accepted"
                >
                    <span v-html="$translate('BUYING_TICKET')"></span>
                    <div class="container">{{ items.tickets_count }}장 보유</div>
                </button>
            </div>
            <div class="bottom">
                <div class="btn-container">
                    <span
                        @click="refundIntroduction"
                        class="refund-policy-text"
                        v-html="$translate('HOW_TO_REFUND_TICKET')"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ActionContactRequest',
    props: ['message'],
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        items() {
            return this.$store.getters.items || {}
        },
        ticketCount() {
            return (this.$store.getters.items || {}).tickets_count
        },
        chatType() {
            return this.chat.chat_type || ''
        },
        title() {
            if (this.chatType === 'real_friend') {
                let msg
                if (this.isAgentUserInRealFriendChat) {
                    msg = this.$translate('ACTION_REAL_FRIEND_AGENT_CONTACT_REQUEST_TITLE')
                } else {
                    msg = this.$translate('ACTION_REAL_FRIEND_CONTACT_REQUEST_TITLE')
                }
                const name = this.$nameOrNick(this.chat.user)
                return msg.replace(/%s/, name)
            }

            return this.$translate('ACTION_CONTACT_REQUEST_TITLE').replace(/%s/, this.content.nickname || '---')
        },
        body() {
            if (this.chatType === 'real_friend' && this.chat.introduce.user_id === this.me.id) {
                const name = this.$nameOrNick(this.chat.user)

                return this.$translate('ACTION_REAL_FRIEND_AGENT_CONTACT_REQUEST_BODY').replace(/%s/, name || '---')
            }

            return this.$translate('ACTION_CONTACT_REQUEST_BODY')
        },
        isAgentUserInRealFriendChat() {
            if (this.chatType !== 'real_friend') return false

            return this.chat.introduce.user_id === this.me.id
        },
        showProfileInfo() {
            if (this.chatType === 'real_friend') {
                return false
            }

            return true
        },
        showButton() {
            if (this.chatType === 'real_friend' && this.isAgentUserInRealFriendChat) {
                return false
            }

            return true
        },
    },
    methods: {
        async onAcceptDating() {
            try {
                const currentComponent = this
                let data

                if (this.chatType === 'real_friend') {
                    data = await chatService.acceptIntroduce(this.chat.id, this.message.id)
                } else {
                    data = await chatService.acceptDating(this.chat.id, this.message.id)

                    if (this.$isPremium(this.chat.agentId)) {
                        this.$bus.$emit('updateDatingCount')
                    }
                }

                this.$modal
                    .custom({
                        component: 'ModalAlertNoX',
                        options: {
                            title: 'INFO',
                            body: data.msg,
                        },
                    })
                    .then(() => {
                        if (this.me.dating_preferences.length > 0) return

                        setTimeout(() => {
                            currentComponent.$modal.custom({
                                component: 'ModalEditDatingPreference',
                            })
                        }, 400)
                    })
                this.sendAirbridgeEvent()
                const c = this.$mustParse(this.message.content)
                c.accepted = true
                this.message.content = JSON.stringify(c)
                this.items.tickets_count = this.items.tickets_count - 1
                this.$store.commit('setItems', this.items)

                const newChat = { ...this.chat }
                if (this.chatType === 'real_friend') {
                    newChat.expire_at = null
                    newChat.introduce.request.accept_status = 'success'
                } else if (this.chat.expire_at) {
                    newChat.expire_at = this.$moment(this.chat.expire_at).add(14, 'days').format()
                }

                this.$store.commit('updateChatAndChats', newChat)
            } catch (e) {
                this.$alert(e.data.msg)
            }
        },
        async buyContact() {
            if (this.content.accepted) {
                this.$alert('이미 교환된 연락처입니다 :)')
                return
            }

            const idx = await this.$modal.basic({
                title: '연락처 교환',
                body: '보유하신 소개팅권을 사용하여<br>연락처를 교환합니다.',
                buttons: [
                    {
                        label: 'CLOSE',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                this.onAcceptDating()
            }
        },
        buyTicket() {
            this.$modal
                .basic({
                    title: '소개팅권 구매',
                    body: '소개팅권이 필요해요!<br>구매 후, 만남까지 성사되지 않으면 100% 환급됩니다.',
                    buttons: [
                        {
                            label: 'CLOSE',
                            class: 'btn-default',
                        },
                        {
                            label: 'BUY',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'Enter_TicketOrderPage',
                            },
                        })

                        this.$stackRouter.push({
                            name: 'PgStorePage',
                            props: {
                                productType: 'ticket',
                            },
                        })
                    }
                })
        },
        refundIntroduction() {
            if (this.chatType === 'real_friend') {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: 'https://www.notion.so/vanillabridge/_-b82b1ec5284d4f65adec1167ff9cda02',
                })

                return
            }

            this.$stackRouter.push({ name: 'RefundPolicyPage' })
        },
        sendAirbridgeEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'user_match_success',
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.action-contact-request {
    .profile-info-container {
        padding: 0 12px;

        .profile-info-box {
            padding: 10px 16px;
            margin-bottom: 16px;
            width: 100%;
            height: 60px;
            border-radius: 10px;
            text-align: left;
            justify-content: space-between;
            border: solid 1px $grey-02;

            @include flex;

            .name {
                font-size: 14px;
                color: black;

                @include f-medium;
            }

            .height-region {
                color: $grey-07;
                font-size: 12px;
                letter-spacing: -0.2px;
                line-height: normal;

                @include flex-row;

                .bar {
                    color: $grey-04;
                }
            }
        }
    }

    .btn-container {
        padding: 0 12px;
        justify-content: center;

        &.kakaoMode {
            button {
                background: $yellow-kakao;
                color: black;
            }

            .container {
                color: black !important;
            }
        }

        .btn {
            box-shadow: none;
            font-size: 14px;

            .container {
                margin-left: 8px;
                padding: 0 4px;
                border-radius: 6px;
                background-color: white;
                font-size: 11px;
                height: 19px;
                color: $purple-primary;

                @include center;
                @include f-bold;
            }
        }

        .refund-policy-text {
            width: 100%;
            font-size: 13px;
            justify-content: center;
            text-decoration: underline;
        }
    }

    .cheap-ticket {
        text-align: center;
        margin-bottom: 24px;

        a {
            text-decoration: underline;
            color: $purple-primary;
        }
    }

    .bottom {
        margin: 12px 0;

        .btn-container {
            text-align: center;
        }
    }

    .btn-ticket {
        background: $grey-02;
        width: 100%;
        color: $grey-09;
    }
}
</style>
